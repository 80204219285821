// Migrated
<template lang="pug">
.d-flex.number-button-input
  button.bg-transparent.number-button.mr-2.p-0.rounded-circle.transition-color(
    type="button",
    :disabled="min >= value || disableDecrease"
    :class="numberInputClass"
    data-test-decrease
    @click="decrease()"
  )
    fa(icon="minus-circle")

  input.form-control.text-black.number-input.border-0.bg-transparent.px-0.h-100(
    v-model="value"
    type="number"
    :min="min"
    :max="max"
    readonly
  )

  button.bg-transparent.number-button.ml-2.p-0.rounded-circle.transition-color(
    type="button",
    :disabled="max <= value || disableIncrease"
    :class="numberInputClass"
    data-test-increase
    @click="increase()"
  )
    fa(icon="plus-circle")

  slot
</template>

<script>
export default defineNuxtComponent({
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },

    min: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER * -1,
    },

    disableDecrease: {
      type: Boolean,
      default: false,
    },

    disableIncrease: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    numberInputClass () {
      if (this.$isSol) {
        return 'text-vivid-turquoise hover-text-medium-blue'
      }

      return 'text-medium-blue hover-text-orange'
    },
  },

  methods: {
    decrease () {
      this.$emit('onDecrease')
      this.$emit('change', this.value - 1)
    },

    increase () {
      this.$emit('onIncrease')
      this.$emit('change', this.value + 1)
    },
  },

  emits: ['change', 'onIncrease', 'onDecrease'],
})
</script>

<style lang="scss" scoped>
$button-size: 35px;

.number-button-input {
  /* Chrome, Safari, Edge, Opera */
  .number-input::-webkit-outer-spin-button,
  .number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  .number-input[type=number] {
    appearance: none;
    -moz-appearance: textfield;
  }

  .number-input {
    width: 36px;
    height: $button-size;

    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }

  .number-button > svg {
    height: $button-size;
    width: $button-size;
  }
}
</style>
